import React from 'react';
import { Link } from 'react-router-dom';
import writingimg from '../img/writing.jpg';
import { Fragment, useState, useCallback } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next';
import { getExercise, retrieveS3DataAPI } from './CohortAPI';
import {  useSelector } from 'react-redux';
import tokenImgUrl from '../img/token.png';
import Spinner from './Spinner';
import { updateUserAttributesAPI } from './UpdateUserAttributesAPI';
import { updateTokensAPI } from './TokenAPI';

let phrase = {};
if ((localStorage.getItem("dailyLessonsFromAPI")!=null)){
  phrase=JSON.parse(localStorage.getItem("currentPhrase"));
}else{
  phrase= {"phraseOfTheDay":{
    "text":"text",
    "translation":"translation"
  }}
}
let userName = localStorage.getItem("firstName");

export const writingPractice = {
  original_phrase: "default",
  intro: `${localStorage.getItem("firstName")}, you're going to write a short dialogue that might take place between you and a local in the country you are visiting. Imagine you're in a bustling  market and need to find a bathroom. You will ask one of the market vendors for directions. I want you to write out this conversation.",
  dialogueBreakdown: "Here's a breakdown of what your dialogue should include:`,
  questions: [
              {id: "1", ques: "Greet the vendor and ask them where the bathroom is." } , 
              {id: "2", ques: "Write the vendor's response, which will direct you to go straight, then take the second left near a big statue." },
              {id: "3", ques: "Express your thanks and ask for the nearest place to buy a bottle of water."},
              {id: "4", ques: "Have the vendor respond by suggesting a small shop across the square from the statue."},
              {id: "5", ques: "End the conversation with a friendly goodbye."},
          ],
  
  phraseBreakdown: "Use the following phrases to help you construct the dialogue in the language the learner is attempting to learn.",
  phrases: [
      {id: "p1", content: "Scusi (Excuse me)" } , 
      {id: "p2", content: "Dove si trova il bagno? (Where is the bathroom?)" },
      {id: "p3", content: "Dritto (Straight)"},
      {id: "p4", content: "La seconda a sinistra (The second on the left)"},
      {id: "p5", content: "Grazie (Thank you)"},
      {id: "p6", content: "Dove posso comprare una bottiglia d'acqua? (Where can I buy a bottle of water?)"},
      {id: "p7", content: "Arrivederci (Goodbye)"},
      ],

  note: "Try to write the dialogue as if it's happening in real time, and imagine the scene in your mind to help with the details.",    

}

const ListItemNumber = ({ count, text }) => {
  return (
    <li key={count} className="text-body-color dark:text-dark-6 flex text-base mb-2">
      <span className="bg-violet-500 mr-2.5 flex h-[26px] w-full max-w-[26px] items-center justify-center rounded-full text-base text-white">
        {count}
      </span>
      {text}
    </li>
  );
};

const ListItemArrow = ({ count, text }) => {
  return (
    <li key={count} className="flex text-base text-body-color dark:text-dark-6 mb-2">
      <span className="mr-2.5 mt-0.5 text-primary">
        <svg
          width={20}
          height={20}
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 9.5L11.5312 2.9375C11.25 2.65625 10.8125 2.65625 10.5312 2.9375C10.25 3.21875 10.25 3.65625 10.5312 3.9375L15.7812 9.28125H2.5C2.125 9.28125 1.8125 9.59375 1.8125 9.96875C1.8125 10.3438 2.125 10.6875 2.5 10.6875H15.8437L10.5312 16.0938C10.25 16.375 10.25 16.8125 10.5312 17.0938C10.6562 17.2188 10.8437 17.2812 11.0312 17.2812C11.2187 17.2812 11.4062 17.2188 11.5312 17.0625L18 10.5C18.2812 10.2187 18.2812 9.78125 18 9.5Z"
            fill="currentColor"
          />
        </svg>
      </span>
      {text}
    </li>
  );
};

export function WritingExercise() {

  const [openWriting, setOpenWriting] = useState(false);
  const { t } = useTranslation();
  const [isFetching, setIsFetching] = useState(false);
  console.log("Opening Writing Module");
  const user = useSelector(state => state.user.userInfo);
  let lang = localStorage.getItem("learningLanguage");
  let native = localStorage.getItem("nativeLanguage");
  console.log(user);

  if(user != null){
    lang = user.attributes['custom:learning-language'];
    native = user.attributes['custom:native-language'];
  }
  let learnExercise =  useCallback(() => { 
    console.log("USER = " + user);
    let writingPracticeStored = JSON.parse(localStorage.getItem("writingPracticeStored"));
    let tokenAmount;
    if (!writingPracticeStored || (writingPracticeStored && writingPracticeStored.original_phrase !== phrase.phraseOfTheDay.text)){
      tokenAmount=2;
    }else{
      tokenAmount=0;
    }
    if(user != null){
      lang = user.attributes['custom:learning-language'];
      native = user.attributes['custom:native-language'];
    }else{
      lang = localStorage.getItem("learningLanguage");
      native = localStorage.getItem("currentLanguage");
    }
    setIsFetching(true);
    if (phrase){
      phrase = JSON.parse(localStorage.getItem("currentPhrase"));
      if (!writingPracticeStored ||  writingPracticeStored.original_phrase !== phrase.phraseOfTheDay.text ){
        console.log("the proper conditions exist to return data from writing practice")
        updateTokensAPI(2).then(function(response){
          if (!response){
            alert('update tokens failed');
            setIsFetching(false);
            return false;
          }else if (response.body['message'] == 'Insufficient Tokens.'){
              setIsFetching(false);
              console.log('Insufficient funds, please reload tokens..........');
              alert('Insufficient Funds ..............');
              return false;
          }else{
              let data = [{ "Name": "custom:token-balance", "Value": response.body["tokens left"].toString()}];
              updateUserAttributesAPI(data);
              //cached code
              retrieveS3DataAPI(phrase.phraseOfTheDay.text, "writing").then(function(response){
                if(response){
                  writingPractice.intro=response.intro;
                  writingPractice.dialogueBreakdown=response.dialogueBreakdown;
                  writingPractice.questions=response.questions;
                  writingPractice.phraseBreakdown=response.phraseBreakdown;
                  writingPractice.phrases=response.phrases;
                  writingPractice.original_phrase=phrase.phraseOfTheDay.text;
                  localStorage.setItem("writingPracticeStored",JSON.stringify(writingPractice)); 
                  setIsFetching(false);
                  setOpenWriting(true);   
                }else{
                  getExercise(lang, native, phrase.phraseOfTheDay.text, "writing", JSON.stringify(writingPractice)).then(function(data){
                    console.log(data)
                    writingPractice.intro=data.intro;
                    writingPractice.dialogueBreakdown=data.dialogueBreakdown;
                    writingPractice.questions=data.questions;
                    writingPractice.phraseBreakdown=data.phraseBreakdown;
                    writingPractice.phrases=data.phrases;
                    writingPractice.original_phrase=phrase.phraseOfTheDay.text;
                    localStorage.setItem("writingPracticeStored",JSON.stringify(writingPractice)); 
                    setIsFetching(false);
                    setOpenWriting(true);   
                  })
                }
              })
            }
        })
      } else {
        console.log("no proper conditions exist to return data from writing practice")
        writingPractice.intro=writingPracticeStored.intro;
        writingPractice.dialogueBreakdown=writingPracticeStored.dialogueBreakdown;
        writingPractice.questions=writingPracticeStored.questions;
        writingPractice.phraseBreakdown=writingPracticeStored.phraseBreakdown;
        writingPractice.phrases=writingPracticeStored.phrases;
        writingPractice.original_phrase=phrase.phraseOfTheDay.text;
        setIsFetching(false);
        setOpenWriting(true);
      }
    }
  }, [])

  return (
    <>
      <li key="readingid">
        <img className="mx-auto h-72 w-72 rounded-full" src={writingimg} alt="" />
        <Link className="relative px-7 py-2 font-medium text-white group mt-4" onClick={() => learnExercise(true)}>
          <span className="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform translate-x-0 -skew-x-12 bg-purple-500 group-hover:bg-purple-700 group-hover:skew-x-12"></span>
          <span className="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform skew-x-12 bg-purple-700 group-hover:bg-purple-500 group-hover:-skew-x-12"></span>
          <span className="absolute bottom-0 left-0 hidden w-10 h-20 transition-all duration-100 ease-out transform -translate-x-8 translate-y-10 bg-purple-600 -rotate-12"></span>
          <span className="absolute bottom-0 right-0 hidden w-10 h-20 transition-all duration-100 ease-out transform translate-x-10 translate-y-8 bg-purple-400 -rotate-12"></span>
          <span className="relative">{t('Writing')}</span>
          <div className="absolute -top-0 -right-0 -mt-3 -mr-4 px-3 py-1 h-9 w-9 rounded-full
                  bg-cover bg-center" style={{backgroundImage: `url(${tokenImgUrl})`}}>
            <div className="font-extrabold text-purple-500 text-lg pr-1 pt-0.5">2</div>
          </div>
        </Link>
      </li>


    {/* Modal Pop up for reading exercise */}

    {isFetching && 
          <div id="spinner" className="w-full">
            <Spinner/>
          </div>        
      }
    <Transition.Root show={openWriting} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpenWriting}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            >
              <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
                <div className="relative flex w-full items-center overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                  <button
                    type="button"
                    className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8"
                    onClick={() => setOpenWriting(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  <div className="grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-12 lg:items-center lg:gap-x-8">


                    {/* Reading Exercise */}
                    <div className="bg-white shadow sm:rounded-lg col-span-12 p-2"> 
                    
                    <div className='pb-5'></div>

                      <div className="bg-sky-600">
                        <div className="px-4 py-5 sm:p-6">
                          <h2 className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-4xl">{t('Writing Lesson')}</h2>
                        </div>
                      </div>
                      <br/>

                      <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 m-4">
                        <div className="flex">
                          <div className="ml-3">
                            <p className="text-md text-yellow-700">
                              {writingPractice.intro}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="border-t-4 border-b-4 border-violet-400 bg-violet-50 p-4 shadow-md m-4">
                        <div className="flex">
                          <div className="ml-3 text-violet-800">
                              {writingPractice.dialogueBreakdown}:
                              <ol className='p-4'>
                              {writingPractice.questions.map((question) => (
                                <ListItemNumber key={question.id} count={question.id} text={question.ques} />
                              ))}
                              </ol>
                          </div>
                        </div>
                      </div>

                      <div className="border-t-4 border-b-4 border-violet-400 bg-violet-50 p-4 shadow-md m-4">
                        <div className="flex">
                          <div className="ml-3 text-violet-800">
                              {writingPractice.phraseBreakdown}:
                              <ul className="p-4 list-disc">
                              {writingPractice.phrases.map((phrase) => (
                                <ListItemArrow key={phrase.id} count={phrase.id} text={phrase.content} />
                              ))}
                              </ul>
                          </div>
                        </div>
                      </div>

                      <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 m-4">
                        <div className="flex">
                          <div className="ml-3">
                            <p className="text-md text-yellow-700">
                              {writingPractice.note}
                            </p>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </>
  );
}
